<template>
  <div>
    <div id="my-superset-container" style="width:100%; height:100%" />
  </div>
</template>

<script>
import { embedDashboard } from '@superset-ui/embedded-sdk'

export default {

  data() {
    return {
      user: {
        role: '',
      },
    }
  },
  mounted() {
    const { reportId } = this.$route.params
    if (reportId) {
      this.buildFrame(reportId)
    }
  },

  methods: {
    buildFrame(reportId) {
      this.user = this.$user.getCurrentUser()
      embedDashboard({
        id: reportId,
        supersetDomain: process.env.VUE_APP_SUPERSET_URL,
        mountPoint: document.getElementById('my-superset-container'),
        fetchGuestToken: () => this.getToken(reportId),
        dashboardUiConfig: { hideTitle: true },
        debug: true,
      })
    },
    async getToken(reportId) {
      const response = await this.$http.get(`reports/${reportId}/token`)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }

      return response.token
    },
  },
}
</script>

<style>
div#my-superset-container > iframe {
  width: 100%;
  height: 800px;
  border: 0px;
}
</style>
